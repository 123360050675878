import { Outlet, Link } from "react-router-dom";
import Navigation from "../../Components/Navigation/Navigation";
import styles from "./Layout.module.css";
import logo from "./../../Images/roy_logo.png";
import resume from "./../../Resume/RoyChng_Resume.pdf";
import { useRef } from "react";

function Layout() {
  const menu = useRef();

  function clickHandler(e, id) {
    const btn = document.createElement("button");
    btn.setAttribute("data-bs-dismiss", "offcanvas");
    menu.current.insertAdjacentElement("afterbegin", btn);
    btn.click();
    btn.remove();
    if (!id) return;
    document.getElementById(id).scrollIntoView();
  }

  return (
    <>
      <div
        class="offcanvas offcanvas-start"
        tabindex="-1"
        id="offcanvasMenu"
        aria-labelledby="offcanvasMenuLabel"
      >
        <div class="offcanvas-header" ref={menu}>
          <img src={logo} className={styles["logo"]} />
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <ul className={styles["nav__links"]}>
            <li>
              <Link
                to="/"
                className={styles["nav__link"]}
                onClick={clickHandler.bind(null)}
              >
                Home
              </Link>
            </li>
            <li>
              <a
                href="/#experience"
                className={styles["nav__link"]}
                onClick={clickHandler.bind("experience")}
              >
                Experience
              </a>
            </li>
            <li>
              <a
                href="/#projects"
                className={styles["nav__link"]}
                onClick={clickHandler.bind("projects")}
              >
                Projects
              </a>
            </li>
            <li>
              <a
                href="/#skills"
                className={styles["nav__link"]}
                onClick={clickHandler.bind("skills")}
              >
                Skills
              </a>
            </li>
            <li>
              <a
                href={resume}
                target="_blank"
                className={`${styles["nav__link"]} ${styles["nav__button"]}`}
                onClick={clickHandler.bind(null)}
              >
                Resume
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.layout}>
        <Navigation />
        <main>
          <Outlet />
        </main>
      </div>
    </>
  );
}

export default Layout;
