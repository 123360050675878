import styles from "./Navigation.module.css";
import { Link } from "react-router-dom";
import royLogo from "./../../Images/roy_logo.png";
import resume from "./../../Resume/RoyChng_Resume.pdf";

function Navigation() {
  return (
    <header className={styles.header}>
      <nav className={styles.nav}>
        <Link to="/">
          <img src={royLogo} alt="" />
        </Link>
        <ul className={styles["nav__links"]}>
          <li>
            <Link to="/" className={styles["nav__link"]}>
              Home
            </Link>
          </li>
          <li>
            <a href="/#experience" className={styles["nav__link"]}>
              Experience
            </a>
          </li>
          <li>
            <a href="/#projects" className={styles["nav__link"]}>
              Projects
            </a>
          </li>
          <li>
            <a href="/#skills" className={styles["nav__link"]}>
              Skills
            </a>
          </li>
          <li>
            <a
              href={resume}
              target="_blank"
              className={`${styles["nav__link"]} ${styles["nav__button"]}`}
            >
              Resume
            </a>
          </li>
          <li>
            <i
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasMenu"
              aria-controls="offcanvasMenu"
              className={`${styles["mobile-menu"]} fa-solid fa-bars`}
            ></i>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Navigation;
