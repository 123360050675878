import AboutHero from "../../Components/About/AboutHero";
import ProjectPreview from "../../Components/ProjectPreview/ProjectPreview";
import styles from "./Home.module.css";
import valorvortechPreview from "./../../Images/valorvortech_preview.jpg";
import valorvortechIcon from "./../../Images/valorvortech_icon.png";
import turbinethreePreview from "./../../Images/turbinethree_preview.jpg";
import turbinethreeIcon from "./../../Images/youtube_icon.png";
import freecodecampPreview from "./../../Images/freecodecamp_preview.jpg";
import freecodecampIcon from "./../../Images/freecodecamp_icon.png";
import pixifyMainPreview from "./../../Images/pixify_main_preview.png";
import pixifyLogo from "./../../Images/pixify_logo.png";
import forkifyMainPreview from "./../../Images/forkify_main_preview.png";
import forkifyLogo from "./../../Images/forkify_logo.png";
import metroMainPreview from "./../../Images/metro_main_preview.png";
import wssImg from "./../../Images/wss_img.jpeg";
import wssLogo from "./../../Images/wss_logo.png";
import metroLogo from "./../../Images/metro_logo.png";
import SkillsGrid from "../../Components/Skills/SkillsGrid";
import WhyMe from "../../Components/About/WhyMe";
import internshipMainpreview from "./../../Images//internship_main_preview.png";
import internshipLogo from "./../../Images/internship_logo.png";
import lkyMSPhoto from "./../../Images/LKY_MS_photo.jpg";
import lkySTEPPhoto from "./../../Images/LKY_STEP_Photo.jpg";
import iteLogo from "./../../Images/ite_logo.png";
import nypLogo from "./../../Images/nyp_logo.jpg";
import oicPreview from "./../../Images/oic_main_preview.jpg";
import jejakinLogo from "./../../Images/jejakin_logo.jpg";
import mitPreview from "./../../Images/mit_preview.jpg";
import mitIcon from "./../../Images/mit_icon.png";
import gcPreview from "./../../Images/gc_preview.jpg";
import gcIcon from "./../../Images/gc_icon.jpg";

function Home() {
  return (
    <>
      <AboutHero />
      <h2 id="experience" className={styles.h2}>
        Experience
      </h2>
      <ProjectPreview
        img={internshipMainpreview}
        heading="Software Developer Internship"
        summary="Interned at a software startup for 6 months where I had to chance to  lead several projects, and learn multiple new technology stacks."
        icon={internshipLogo}
        accentColor="#E1F3FF"
        buttonColor="#0072BE"
        boxShadowColor="#293462"
        demoTo="/experience/internship"
        infoTo=""
        demoBtnText="Read More"
        showInfoBtn={false}
        demoToNewTab={false}
        reversed
      />
      <ProjectPreview
        img={turbinethreePreview}
        heading="Programming Tutorials"
        summary="Created programming tutorials on Youtube, growing to over 10,000+ subscribers with 1,000,000+ total views in under a year"
        icon={turbinethreeIcon}
        accentColor="#FFD1BA"
        buttonColor="#EA5455"
        boxShadowColor="#950001"
        demoTo="https://youtube.com/@turbinethree"
        infoTo="/experience/valorvortech"
        showInfoBtn={false}
        demoBtnText="Visit"
      />
      <ProjectPreview
        img={valorvortechPreview}
        heading="Founded ValorVortech"
        summary="Gained 170,000+ organic pageviews by writing technology-related articles online"
        icon={valorvortechIcon}
        accentColor="#E1F3FF"
        buttonColor="#0072BE"
        boxShadowColor="#293462"
        demoTo="https://valorvortech.com"
        infoTo="/experience/valorvortech"
        showInfoBtn={false}
        demoBtnText="Visit"
        reversed
      />

      <ProjectPreview
        img={freecodecampPreview}
        heading="FreeCodeCamp Author"
        summary="FreeCodeCamp is a non-profit organization that helps individuals learn to code for free online. I helped contribute several in-depth technical articles to their site, on topics such as React & Python!"
        icon={freecodecampIcon}
        accentColor="#E1FFFB"
        buttonColor="#009581"
        boxShadowColor="#006D5F"
        demoTo="https://www.freecodecamp.org/news/author/roy/"
        infoTo="/experience/valorvortech"
        showInfoBtn={false}
        demoBtnText="Visit"
      />
      <h2 id="competitions" className={styles.h2}>
        Competitions
      </h2>
      <ProjectPreview
        img={oicPreview}
        heading="Overseas Innovation Challenge 2024"
        summary="Spent 2 weeks in Indonesia, Jakarta working with a team of students from Malaysia, Indonesia and Singapore to solve a real-world problem given by a startup"
        icon={jejakinLogo}
        accentColor="#E1FFFB"
        buttonColor="#009581"
        boxShadowColor="#006D5F"
        showInfoBtn={false}
        demoBtnText="Read More"
        demoTo="/competition/oic"
        demoToNewTab={false}
        reversed
      />
      <ProjectPreview
        img={wssImg}
        heading="WorldSkills Singapore (Mobile App Dev)"
        summary="Competed among the best students in Singapore and won 2nd place with S$2,000 in the category of Mobile App Development"
        icon={wssLogo}
        accentColor="#FFE4E4"
        buttonColor="#EA5455"
        boxShadowColor="#950001"
        showInfoBtn={false}
        demoTo="/competition/wss"
        demoBtnText="Read More"
        demoToNewTab={false}
      />
      <h2 id="awards" className={styles.h2}>
        Awards
      </h2>
      <ProjectPreview
        img={lkyMSPhoto}
        icon={iteLogo}
        accentColor="#FFD1BA"
        buttonColor="#EA5455"
        boxShadowColor="#950001"
        heading="Lee Kuan Yew Model Student"
        summary="Awarded the Lee Kuan Yew Model Student award, given to 9 students each year based on their academic and extracurricular achievements"
        showInfoBtn={false}
        showDemoBtn={false}
      />
      <ProjectPreview
        img={lkySTEPPhoto}
        icon={nypLogo}
        accentColor="#FFE4E4"
        buttonColor="#EA5455"
        boxShadowColor="#950001"
        heading="Lee Kuan Yew Scholarship"
        summary="Received the Lee Kuan Yew Scholarship when I enrolled in Nanyang Polytechnic. The sholarship is given to 15 students anually"
        showInfoBtn={false}
        showDemoBtn={false}
        reversed
      />

      <h2 id="projects" className={styles.h2}>
        Projects
      </h2>
      <ProjectPreview
        img={pixifyMainPreview}
        heading="Pixify"
        summary="React Application for sharing free stock images, made with Redux and React-Router"
        icon={pixifyLogo}
        accentColor="#FFE4E4"
        buttonColor="#EA5455"
        boxShadowColor="#950001"
        demoTo="https://roy-pixify.netlify.app/"
        infoTo="/projects/pixify"
        reversed
      />
      <ProjectPreview
        img={forkifyMainPreview}
        heading="Forkify"
        summary="Single page application  for sharing recipes. Uses vanilla JavaScript with MVC architecture. Done as part of a JS course."
        icon={forkifyLogo}
        accentColor="#FFD1BA"
        buttonColor="#F07B3F"
        boxShadowColor="#950001"
        demoTo="https://roy-forkify.netlify.app/"
        infoTo="/projects/forkify"
      />
      <ProjectPreview
        img={metroMainPreview}
        heading="Metro Streetwear"
        summary="Responsive e-commerce store with functional cart and checkout"
        icon={metroLogo}
        accentColor="#E1F3FF"
        buttonColor="#0072BE"
        boxShadowColor="#293462"
        demoTo="https://metro-streetwear.netlify.app/html/"
        infoTo="/projects/metro-streetwear"
        reversed
      />
      {/* <ProjectPreview
        img={blogMainPreview}
        heading="Blog"
        summary="Made using Flask in Python for the backend. Includes account registration, authentication and creating posts."
        icon={blogLogo}
        accentColor="#E1FFFB"
        buttonColor="#009581"
        boxShadowColor="#006D5F"
        demoTo="https://flask-blog-3kph.onrender.com/"
        infoTo="/projects/flask-blog"
        reversed
      /> */}

      <h2 id="skills" className={styles.h2}>
        Skills
      </h2>
      <SkillsGrid />
      <h2 id="extracurriculars" className={styles.h2}>
        Extracurriculars
      </h2>
      <ProjectPreview
        img={gcPreview}
        heading="Guitar Club"
        summary="I am part of NYP's Guitar Club where I have performed at multiple events and concerts"
        icon={gcIcon}
        accentColor="#FFE4E4"
        buttonColor="#EA5455"
        boxShadowColor="#950001"
        showInfoBtn={false}
        showDemoBtn={false}
        reversed
      />
      <ProjectPreview
        img={mitPreview}
        heading="Makers Innovators Tribe"
        summary="I am part of NYP's Makers Innovators Tribe where I learn DIY skills such as 3D printing, WoodWorking, Laser Cutting, Spray Painting and Sewing"
        icon={mitIcon}
        accentColor="#FFD1BA"
        buttonColor="#EA5455"
        boxShadowColor="#950001"
        showInfoBtn={false}
        showDemoBtn={false}
      />
      <h2 id="about" className={styles.h2}>
        About
      </h2>
      <WhyMe />
    </>
  );
}

export default Home;
