import styles from "./WhyMe.module.css";
import resume from "./../../Resume/RoyChng_Resume.pdf";

function WhyMe() {
  return (
    <section className={styles["about"]}>
      <p className={styles["about-desc"]}>
        I look forward to combine my passion for technology and love for the
        arts through my work!
      </p>
      <div className={styles["hero__actions"]}>
        <a
          href="mailto:roychng21@gmail.com"
          target="_blank"
          className={`${styles["hero__btn"]} ${styles["hero__contact"]}`}
        >
          Contact
        </a>
        <a
          href={resume}
          target="_blank"
          className={`${styles["hero__btn"]} ${styles["hero__resume"]}`}
        >
          Resume
        </a>
      </div>
    </section>
  );
}

export default WhyMe;
