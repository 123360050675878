import { Route, Routes } from "react-router-dom";
import Project from "./Project";
import internshipIcon from "../../Images/internship_logo.png";
import internshipImg1 from "../../Images/projects/internship-1.png";
import internshipImg2 from "../../Images/projects/internship-2.png";
import internshipImg3 from "../../Images/projects/internship-3.png";

function Experiences() {
  return (
    <Routes>
      <Route
        path="/internship"
        element={
          <Project
            icon={internshipIcon}
            name="Software Developer Internship"
            color="#0072BE"
            contents={[
              {
                heading: "Developed Payment System",
                desc: "I designed and developed the payment currently being used by the company's clients using PHP, Javascript, HTML and CSS over the span of 3 months",
                img: internshipImg1,
              },
              {
                heading: "Lead A Team of 5 To Develop An App",
                desc: "I worked between my managers and led a team of 5 to develop an app from beginning to end over the span of 4 months using technologies such as Flutter, SQL and NodeJS",
                img: internshipImg2,
              },
              {
                heading: "Worked Under Pressure",
                desc: "Over the course of my internship, I learnt how to juggle multiple responsibilities and projects, working under pressure to meet deadlines",
                img: internshipImg3,
              },
            ]}
          />
        }
      ></Route>
    </Routes>
  );
}

export default Experiences;
