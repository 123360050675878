import { Route, Routes } from "react-router-dom";
import Project from "./Project";
import jejakinIcon from "../../Images/jejakin_logo.jpg";
import oicImg1 from "../../Images/projects/oic_img_1.jpeg";
import oicImg2 from "../../Images/projects/oic_img_2.jpeg";
import wssImg1 from "../../Images/projects/wss-1.png";
import wssImg2 from "../../Images/projects/wss-2.jpeg";
import wssIcon from "../../Images/wss_logo.png";

function Competitions() {
  return (
    <Routes>
      <Route
        path="/oic"
        element={
          <Project
            icon={jejakinIcon}
            name="Overseas Innovation Challenge 2024"
            color="#009581"
            contents={[
              {
                heading: "Collaboration With Overseas Teammates",
                desc: "Over the course of the 2-week challenge, I got the chance to work with a diverse group of teammates coming from different backgrounds, schools and nationalities - finding common ground to push progress forward",
                img: oicImg1,
              },
              {
                heading: "Made New Friends!",
                desc: "I made close friends with some of the Malaysian students and joined them to explore Indonesia over the weekends!",
                img: oicImg2,
              },
            ]}
          />
        }
      />
      <Route
        path="/wss"
        element={
          <Project
            icon={wssIcon}
            name="WorldSkills Singapore 2023"
            color="#EA5455"
            contents={[
              {
                heading: "Design, Development & Testing",
                desc: "Over a 3-day competition, I had to design, develop and test multiple apps under time pressure using Flutter and Adobe XD",
                img: wssImg1,
              },
              {
                heading: "Silver Medalist",
                desc: "I managed to win 2nd place from this competition, where I learnt how to work under time pressure, become mentally resilient and to prioritise my tasks based on what is most important",
                img: wssImg2,
              },
            ]}
          />
        }
      />
    </Routes>
  );
}

export default Competitions;
